<template>
  <div class="z-30 my-2 w-full md:px-4">
    <div
      class="flex w-full flex-col items-center justify-between gap-2 md:flex-row"
    >
      <slot />

      <div
        class="ml-4 flex flex-wrap items-center gap-2 self-start md:self-end"
      >
        <slot name="filters" />
      </div>
    </div>
  </div>
</template>
